window.bootstrap = require('bootstrap/js/dist/collapse');

document.querySelectorAll(".accordion-item").forEach((el)=>{
  el.addEventListener('shown.bs.collapse', () => {
    const headerOffset = document.querySelector('.navbar-wrapper').getBoundingClientRect().height;
    const elementPositionAndOffset = el.getBoundingClientRect().top + window.pageYOffset;
    var offsetPosition;
    const mediaQuery = window.matchMedia('(min-width: 992px)')
    if (mediaQuery.matches) {
      offsetPosition = elementPositionAndOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  })
});
